<template>
    <div>
        <!-- 顶部工具条 -->
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item label='关键字:'>
                    <el-input placeholder="姓名/身份证/手机号/单位名称" 
                        v-model="filters.searchKey"
                        clearable
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="批次查询:">
                    <el-select v-model="filters.examBatch" clearable placeholder="请选择">
                        <el-option 
                            v-for="item in examBatchArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                        <el-pagination
                            layout="prev, pager, next"
                            @current-change="currentChange"
                            :total="brandListPage.DataCount">
                        </el-pagination>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label='省：'>
                    <el-select v-model="filters.provinceID" clearable @change='selProvince' placeholder="请选择">
                        <el-option
                            v-for="item in provinceIDArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <!-- <el-form-item label='市：'>
                    <el-select v-model="filters.cityID" clearable  @change='selCity' placeholder="请选择">
                        <el-option
                            v-for="item in cityIDArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='区/县：'>
                    <el-select v-model="filters.regionID" clearable placeholder="请选择">
                        <el-option
                            v-for="item in regionIDArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="地区:">
                    <el-cascader :props="props" v-model="filters.selecAreaArr" clearable></el-cascader>
                </el-form-item>
                <el-form-item label="岗位:" placeholder="请选择岗位">
                    <el-select v-model="filters.station" clearable>
                        <el-option 
                            v-for="item in stationArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="行业:" placeholder="请选择行业">
                    <el-select v-model="filters.trade" clearable>
                        <el-option 
                            v-for="item in tradeArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                </el-form-item>
            </el-form>
        </el-col>
        <!-- 表格 -->
        <el-table 
            v-loading="tableLoading"
            :data="tableData" 
            style="width:100%" 
            border 
            ref="multipleTable"
            highlight-current-row 
            @current-change="handlerowChange"
            @selection-change="handleSelectChange"
        >
            <el-table-column type="selection" width="45"></el-table-column>
            <el-table-column label="批次名称" min-width="100" align="center" prop="ExamBatchPopulationName"></el-table-column>
            <el-table-column label="姓名" width="" align="center" prop="Name"></el-table-column>
            <el-table-column label="身份证号" min-width="100" align="center" prop="CardNo"></el-table-column>
            <el-table-column label="手机号" width="" align="center" prop="PhoneNumber"></el-table-column>
            <el-table-column  label="数据来源" width="120" align="center" prop="RequestSource">
                <!-- <template slot-scope="scope">
                    <el-tag :type="'success'" v-if="scope.row.QuestionsType == 1">iOS</el-tag>
                    <el-tag :type="'warning'" v-if="scope.row.QuestionsType == 2">Android</el-tag>
                </template> -->
            </el-table-column>
            <el-table-column  label="单位名称" min-width="100" align="center" prop="UnitName"></el-table-column>
            <!-- <el-table-column  label="单位所在省" width="" align="center" prop="Province"></el-table-column> -->
            <el-table-column  label="单位所在市" min-width="120" align="center" prop="City"></el-table-column>
            <el-table-column  label="单位所在区" min-width="120" align="center" prop="Region"></el-table-column>
            <el-table-column  label="岗位名称" min-width="100" align="center" prop="StationName"></el-table-column>
            <el-table-column  label="行业名称" min-width="100" align="center" prop="TradeName"></el-table-column>
            <el-table-column  label="允许考试" min-width="100" align="center" prop="IsAllowExamination">
                <template slot-scope="scope">
                    <el-tag :type="'success'" v-if="scope.row.IsAllowExamination == 1">允许</el-tag>
                    <el-tag :type="'warning'" v-if="scope.row.IsAllowExamination == 2">拒绝</el-tag>
                    <el-tag v-if="scope.row.IsAllowExamination == 3">取消</el-tag>
                </template>
            </el-table-column>
            <el-table-column  label="报名时间" width="110" align="center" prop="CreateTime"></el-table-column>
            <el-table-column  label="免培考试截至时间" min-width="150" align="center" prop="OverdueTime"></el-table-column>
            <el-table-column  label="免培考试交卷时间" min-width="150" align="center" prop="HandTime"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-col :span="24" class="pageBarContainer">
            <div>已选择<span class="selectNumCla">{{allSelect.length}}</span>条</div>
            <div>
                <el-pagination
                    small
                    :page-sizes="pages.pageArr"
                    :page-size="pages.pageSize"
                    :current-page="pages.pageIndex"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pages.dataCount"
                    @size-change="handleSizeChange" 
                    @current-change="handleCurrentChange"
                    class="pageBar"       
                    >
                </el-pagination>
            </div>
        </el-col>
    </div>
</template>

<script>
import util from "../../../util/date";
import Qs from 'qs' 
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
// 数据请求
import { getExamEnrollInfoListPage,getDataDictionaryList,getAreaDictionaryList,getExamBatchPopulationList, } from '@/api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList: [],
            filters:{
                searchKey:'',
                examBatch:'',
                provinceID: 1919,
                cityID: '',
                regionID: '',
                selecAreaArr: [],
                station: '',
                trade: '',
            },
            examBatchArr: [],
            provinceIDArr: [],
            cityIDArr: [],
            regionIDArr: [],
            stationArr: [], //岗位
            tradeArr: [], //行业
            // 批次分页
            brandListPage: {
                DataCount: 10,
                pageIndex: 1,
                pageSize: 10,
            },
            // 表格
            tableData: [],
            tableLoading: false,
            allSelect: '',
            currentRow: '',
            // 分页
            pages:{
                pageArr:[20,60,100,200,300],
                pageSize:20,
                pageIndex:1,
                dataCount:1,
            },
            // 地区级联选择
            props: {
                lazy: true,
                checkStrictly: true,
                lazyLoad (node, resolve) {
                    var level = false
                    if(node.value > 0) {
                        var params = {
                            parentId: node.value,
                            level: 2,
                        }
                        level = true
                    } else {
                        var params = {
                            parentId: 1919,
                            level: 1,
                        }
                        level = false
                    }
                    getAreaDictionaryList(params).then(res => {
                        if(res.data.Success){
                            var nodes = res.data.Response.reverse()
                            nodes.forEach(item => {
                                item.value = item.ID
                                item.label = item.Name
                                item.leaf = level
                            })
                            resolve(nodes);
                        }
                    })
                }
            },
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 查询
        getBatchManagement() {
            this.getTableDataFun();
        },
        // 列表
        getTableDataFun() {
            var params = {
                keyWord: this.filters.searchKey,
                examBatchPopulationID: this.filters.examBatch,
                provinceID: this.filters.provinceID,
                // cityId: this.filters.cityID,
                // regionId: this.filters.regionID,
                stationID: this.filters.station,
                tradeID: this.filters.trade,
                batchStatus: 1,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            if(this.filters.selecAreaArr.length > 0) {
                params.cityID = this.filters.selecAreaArr[0]
            }
            if(this.filters.selecAreaArr.length > 1) {
                params.regionID = this.filters.selecAreaArr[1]
            }
            this.tableLoading = true
            getExamEnrollInfoListPage(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.tableLoading = false
                    this.tableData = result.Response.Data
                    this.pages.dataCount = result.Response.DataCount
                }
            })
        },
        // 分页
        handleSizeChange(newSize){
            this.pages.pageSize = newSize   
            this.getTableDataFun()
        },
        handleCurrentChange(newPage){
            this.pages.pageIndex = newPage      
            this.getTableDataFun()
        },
        // 表格当前行改变事件
        handlerowChange(currentRow){
            this.currentRow = currentRow
        },
        // 选中表格行
        handleSelectChange(allRow){
            this.allSelect = allRow
        },
        // 获取岗位/行业
        getDataDictionaryList() {
            var params = {}
            getDataDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    result.Response.forEach(item => {
                        if(item.DataType == 0) {
                            this.tradeArr.push(item)
                        } else if(item.DataType == 1) {
                            this.stationArr.push(item)
                        }
                    });
                }
            })
        },
        // 选中省、市、区当前行
        selProvince(value){
            this.filters.cityID = ''
            this.filters.regionID = ''
            this.parentProvinceID = value
            var params = {
                level:1,
                parentId:this.parentProvinceID
            }
            this.getCity(params)
        },
        selCity(value){
            this.parentCityID = value
            var params = {
                parentId:this.parentCityID
            }
            this.getArea(params)
        },
        // 获取省、市、区的数据
        getProvince(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.provinceIDArr = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        getCity(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.cityIDArr = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        getArea(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.regionIDArr = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        // 获取批次
        getExamBatchFun() {
            var params = {
                status: 1,
                pageIndex: this.brandListPage.pageIndex,
                pageSize: this.brandListPage.pageSize,
            }
            getExamBatchPopulationList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.examBatchArr = result.Response.Data
                    this.brandListPage.DataCount = result.Response.DataCount
                }
            })
        },
        currentChange(val) {
            this.brandListPage.pageIndex = val
        },
        // 时间格式化
        formatCreateTime:function(row,column){
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        }, 
    },
    created(){
        this.getTableDataFun();
        this.getDataDictionaryList();
        // var params = {
        //     level:0
        // }
        // this.getProvince(params)
        var params = {
            parentId: 1919,
            level: 1,
        }
        // this.getCity(params)
        this.getExamBatchFun()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    .pageBarContainer {
        display: flex;
        align-items: center;
        font-size: 14px;
    }
    .selectNumCla {
        color: #409EFF;
        font-weight: bold;
        margin: 0 5px;
        font-size: 16px;
    }
</style>